import { GoThreeBars } from "react-icons/go";
import { FaTimes } from "react-icons/fa";
import { useState } from "react";
import { Link } from "react-router-dom";
import { SignInSignUpModal } from "../modals";

const Navbar = () => {
  const [showItems, setShowItems] = useState(false);
  const [selectType, setSelectType] = useState("");

  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleShowDropdown = (type) => {
    if (type == selectType) {
      setSelectType("");
    } else {
      setSelectType(type);
    }
  };

  const handleShowLoginModal = () => {
    setShowLoginModal(true);
  };

  return (
    <>
      <header style={{ zIndex: 1000 }}>
        <div className="container-fluid">
          <div className="top_navbar">
            {/* <div className="nav-container" tabIndex="0"> */}
            {!showItems && (
              <span
                onClick={() => setShowItems(true)}
                style={{ cursor: "pointer", paddingLeft: "1rem" }}
              >
                <GoThreeBars size={30} color="grey" />
              </span>
            )}
            {/* <div className="nav-toggle"></div> */}
            {showItems && (
              <div className="navItems">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowItems(false)}
                >
                  <FaTimes size={28} color="grey" />
                </span>
                <ul style={{ overflowY: "scroll" }}>
                  <li>
                    <Link to="/">New In</Link>
                  </li>
                  <li>
                    {/* <Link href="category.html">Categories</Link> */}
                    <Link to="/categories" onClick={() => setShowItems(false)}>
                      Categories
                    </Link>
                  </li>
                  <li>
                    <Link to="/">Collections</Link>
                  </li>
                  <li>
                    <Link to="/">Trending</Link>
                  </li>
                  <li>
                    <Link to="/">Styles</Link>
                  </li>
                  <li>
                    <Link to="/">Blog</Link>
                  </li>
                  <li>
                    <Link to="/">Gifting</Link>
                  </li>
                  <li>
                    <Link to="/">Membership</Link>
                  </li>
                  <li>
                    <Link to="/">Services</Link>
                  </li>
                  <li>
                    <Link to="/">About Us</Link>
                  </li>
                </ul>
              </div>
            )}
            {/* </div> */}
            <div className="logo">
              <Link to="/" onClick={() => setShowItems(false)}>
                The Concept Key
              </Link>
            </div>
            <div className="top_menu" style={{ width: "100%" }}>
              <ul style={{ width: "100%", justifyContent: "flex-end" }}>
                <li>
                  <form className="search">
                    <div className="search_wrapper">
                      <input
                        type="text"
                        name=""
                        placeholder=""
                        className="form-control"
                      />
                      <button type="submit">
                        <i className="flaticon-search"></i>
                      </button>
                    </div>
                  </form>
                </li>
                <li>
                  <a
                    onClick={handleShowLoginModal}
                    data-toggle="modal"
                    data-target="#signIn_modal"
                  >
                    <i className="flaticon-user"></i>
                  </a>
                </li>
                <li>
                  <a>
                    <Link to="/bag-cart" onClick={() => setShowItems(false)}>
                      <i className="flaticon-shopping-bag"></i>
                    </Link>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="navbar navbar-expand-md" id="scroll_nav">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link"
                    id="navbarDropdown"
                    role="button"
                    onClick={() => handleShowDropdown("collections")}
                  >
                    Collections
                  </a>
                  <div
                    className={`dropdown-menu ${
                      selectType == "collections" && "show"
                    }`}
                    aria-labelledby="navbarDropdown"
                    // style={{ zIndex: 10000 }}
                  >
                    <div className="down-slow">
                      <a>Mattis</a>
                      <a>Semper</a>
                      <a>Bibendum</a>
                      <a>Eget</a>
                      <a>Nuncet</a>
                      <a>Bibendum</a>
                      <a>Eget</a>
                      <a>Nuncet</a>
                      <a>Bibendum</a>
                    </div>
                  </div>
                </li>
                <li className="nav-item active">
                  <a className="nav-link">Decor</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link">Kitchen</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link">Lifestyle</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link">Lighting</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link">New In</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link">Furniture</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link">Gifting</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      {showLoginModal && (
        <SignInSignUpModal setShowLoginModal={setShowLoginModal} />
      )}
    </>
  );
};

export default Navbar;
