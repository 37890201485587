import { Carousel } from "react-bootstrap";
import { useEffect } from "react";
import Slider from "react-slick";
import Innovation_image1 from "../../images/path01.svg";
import Innovation_image2 from "../../images/path02.svg";
import Innovation_image3 from "../../images/path03.svg";
import ViewAll from "../../images/view_all.svg";

const HomePage = () => {
  let settings = {
    speed: 750,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    slidesToShow: 4,
    slidesToScroll: 0.1,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 0.1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 0.1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 0.1,
        },
      },
    ],
  };
  let testimonals = {
    centerMode: true,
    dots: false,
    centerPadding: "0px",
    infinite: true,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require("../../images/banner01.jpg")}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require("../../images/banner02.jpg")}
            alt="Second slide"
          />
        </Carousel.Item>
      </Carousel>
      {/* <section className="banner_slide">
        <div id="demo" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={require("../../../../images/banner01.jpg")} alt="banner" />
            </div>
            <div className="carousel-item">
              <img src={require("../../images/banner02.jpg")} alt="banner" />
            </div>
          </div>

          <a className="carousel-control-prev" href="#demo" data-slide="prev">
            <img src={require("../../images/prev.svg")} alt="prev" />
          </a>
          <a className="carousel-control-next" href="#demo" data-slide="next">
            <img src={require("../../images/next.svg")} alt="next" />
          </a>
        </div>
      </section> */}

      <div className="container-flex">
        <section className="our-brands flex-item">
          <div className="brans_img">
            <div className="brand-img animation-effect">
              <a href="product-page.html">
                <img
                  src={require("../../images/Our-Brands/img01.jpg")}
                  alt="brand-01"
                />
                <h6 className="tittle-brand">massa</h6>
              </a>
            </div>
            <div className="brand-img animation-effect">
              <a href="product-page.html">
                <img
                  src={require("../../images/Our-Brands/img02.jpg")}
                  alt="brand-01"
                />
                <h6 className="tittle-brand text-dark">VELIT</h6>
              </a>
            </div>
            <div className="brand-img animation-effect">
              <a href="product-page.html">
                <img
                  src={require("../../images/Our-Brands/img03.jpg")}
                  alt="brand-01"
                />
                <h6 className="tittle-brand">TELLUS</h6>
              </a>
            </div>
          </div>
          <div className="brans_content content-right">
            <h2 className="tittle_heading">Our Brands</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
            </p>
            <a href="#" className="view-btn">
              View All
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                // xml:space="preserve"
              >
                <g>
                  <g>
                    <path
                      d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068
                       c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557
                       l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104
                       c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z"
                    />
                  </g>
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </a>
          </div>
        </section>

        <section className="curated_by">
          <div className="row">
            <div className="col-sm-12 col-md-5 col-lg-5 align-self-center">
              <div className="brans_content content-left w-100">
                <h2 className="tittle_heading">Curated by us</h2>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam
                </p>
                <a href="#" className="view-btn">
                  View All
                  <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    // xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    // xml:space="preserve"
                  >
                    <g>
                      <g>
                        <path
                          d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068
                    c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557
                    l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104
                    c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z"
                        />
                      </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </a>
              </div>
            </div>
            <div className="col-sm-12 col-md-7 col-lg-7">
              <div
                style={{
                  display: "flex",
                  padding: "1rem",
                }}
              >
                <div style={{ display: "flex", overflowX: "scroll" }}>
                  <div
                    className="curated_by_div"
                    style={{ height: "500px", minWidth: "300px" }}
                  >
                    <img
                      src={require("../../images/Curated-by/img01.jpg")}
                      alt="img"
                    />
                    <span className="curated_by_name">Aliquet</span>
                    <span className="curated_by_price">₹ 990</span>
                  </div>
                  <div
                    className="curated_by_div"
                    style={{ height: "500px", minWidth: "300px" }}
                  >
                    <img
                      src={require("../../images/Curated-by/img02.jpg")}
                      alt="img"
                    />
                    <span className="curated_by_name">Aliquet</span>
                    <span className="curated_by_price">₹ 990</span>
                  </div>
                  <div
                    className="curated_by_div"
                    style={{ height: "500px", minWidth: "300px" }}
                  >
                    <img
                      src={require("../../images/Curated-by/img03.jpg")}
                      alt="img"
                    />
                    <span className="curated_by_name">Aliquet</span>
                    <span className="curated_by_price">₹ 990</span>
                  </div>
                  <div
                    className="curated_by_div"
                    style={{ height: "500px", minWidth: "300px" }}
                  >
                    <img
                      src={require("../../images/Curated-by/img04.jpg")}
                      alt="img"
                    />
                    <span className="curated_by_name">Aliquet</span>
                    <span className="curated_by_price">₹ 990</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <h2 style={{ marginBottom: "1rem" }}>Trending Now</h2>
          <Slider {...settings} className="trending_slide">
            <div className="trending_div">
              <img src={require("../../images/trending-now/img01.jpg")} />
              <span className="curated_by_name">Lacus </span>
              <span className="curated_by_price">₹ 499</span>
            </div>
            <div className="trending_div">
              <img
                src={require("../../images/trending-now/img02.jpg")}
                alt="img"
              />

              <span className="curated_by_name">Odio</span>
              <span className="curated_by_price">₹ 2199</span>
            </div>
            <div className="trending_div">
              <img
                src={require("../../images/trending-now/img03.jpg")}
                alt="img"
              />

              <span className="curated_by_name">Orcarc</span>
              <span className="curated_by_price">₹ 3199</span>
            </div>
            <div className="trending_div">
              <img
                src={require("../../images/trending-now/img04.jpg")}
                alt="img"
              />

              <span className="curated_by_name">Egestas</span>
              <span className="curated_by_price">₹ 899</span>
            </div>
            <div className="trending_div">
              <img
                src={require("../../images/trending-now/img05.jpg")}
                alt="img"
              />

              <span className="curated_by_name">Justuo</span>
              <span className="curated_by_price">₹ 999</span>
            </div>
            <div className="trending_div">
              <img
                src={require("../../images/trending-now/img06.jpg")}
                alt="img"
              />

              <span className="curated_by_name">Magnai</span>
              <span className="curated_by_price">₹ 4499</span>
            </div>
            <div className="trending_div">
              <img
                src={require("../../images/trending-now/img07.jpg")}
                alt="img"
              />

              <span className="curated_by_name">Factri</span>
              <span className="curated_by_price">₹ 2599</span>
            </div>
            <div className="trending_div">
              <img
                src={require("../../images/trending-now/img08.jpg")}
                alt="img"
              />

              <span className="curated_by_name">Purus</span>
              <span className="curated_by_price">₹ 1299</span>
            </div>
            <div className="trending_div">
              <img
                src={require("../../images/trending-now/img09.jpg")}
                alt="img"
              />

              <span className="curated_by_name">Donec</span>
              <span className="curated_by_price">₹ 1299</span>
            </div>
            <div className="trending_div">
              <img
                src={require("../../images/trending-now/img10.jpg")}
                alt="img"
              />

              <span className="curated_by_name">Arcu</span>
              <span className="curated_by_price">₹ 699</span>
            </div>
          </Slider>
          <a href="#" className="view-btn d-block text-right">
            View All
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              //   xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              //   xml:space="preserve"
            >
              <g>
                <g>
                  <path
                    d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068
                c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557
                l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104
                c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z"
                  ></path>
                </g>
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
          </a>
        </section>

        <section className="eloquence">
          <div className="col-sm-12">
            <h2 className="tittle_heading">
              Eloquence <br /> The Blog
            </h2>
          </div>
          <Slider {...testimonals} className="regular">
            <div>
              <div className="block_img1">
                <img
                  className="img_count"
                  src={require("../../images/Eloquence/img01.jpg")}
                  alt="img"
                />
                <a className="read_more" href="#">
                  Read <img src={ViewAll} />
                </a>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labor.
              </p>
            </div>
            <div>
              <div className="block_img1">
                <img
                  className="img_count"
                  src={require("../../images/Eloquence/img02.jpg")}
                  alt="img"
                />
                <a className="read_more" href="#">
                  Read <img src={ViewAll} />
                </a>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labor.
              </p>
            </div>
            <div>
              <div className="block_img1">
                <img
                  className="img_count"
                  src={require("../../images/Eloquence/img03.jpg")}
                  alt="img"
                />
                <a className="read_more" href="#">
                  Read <img src={ViewAll} />
                </a>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labor.
              </p>
            </div>
            <div>
              <div className="block_img1">
                <img
                  className="img_count"
                  src={require("../../images/Eloquence/img02.jpg")}
                  alt="img"
                />
                <a className="read_more" href="#">
                  Read <img src={ViewAll} />
                </a>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labor.
              </p>
            </div>
          </Slider>
        </section>

        <section className="we_empower" style={{ margin: "4rem 0" }}>
          <div className="row">
            <div className="col-sm-12 col-md-8 col-lg-8 align-self-center">
              <div className="man_img">
                <img
                  src={require("../../images/we-empower/img01.jpg")}
                  alt="img"
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <div className="brans_content content-right w-100">
                <h3 className="tittle_heading">
                  We empower <br />
                  We Uplift
                </h3>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum.
                </p>
                <a href="#" className="view-btn">
                  Learn More
                  <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    // xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    // xml:space="preserve"
                  >
                    <g>
                      <g>
                        <path
                          d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068
                       c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557
                       l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104
                       c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z"
                        ></path>
                      </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="inviting_designer">
          <div className="row">
            <div className="col-sm-12">
              <h2 className="tittle_heading text-center text-uppercase">
                inviting designers
              </h2>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-5">
              <div className="content-left">
                <h3 className="tittle_heading mb-2">Your key to innovation</h3>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna{" "}
                </p>
                <a href="#" className="view-btn">
                  View All
                  <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    // xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    // xml:space="preserve"
                  >
                    <g>
                      <g>
                        <path
                          d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068
                      c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557
                      l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104
                      c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z"
                        ></path>
                      </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </a>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-7">
              <div className="moving_path">
                <svg
                  version="1.1"
                  id="Layer_1"
                  viewBox="0 0 1027 542"
                  enableBackground="new 0 0 1027 542"
                  //   xml:space="preserve"
                >
                  <g id="Group_97" transform="translate(-620 -5168)">
                    <g
                      id="undraw_ideation_2a64"
                      transform="translate(851.99 5207.6)"
                    >
                      <g>
                        <rect
                          id="Rectangle_62"
                          x="254.998"
                          y="461.885"
                          fill="#3F3D56"
                          width="353.207"
                          height="1.132"
                        />
                        <g className="girl_move">
                          <circle
                            id="Ellipse_15"
                            fill="#A0616A"
                            cx="496.696"
                            cy="190.755"
                            r="14.151"
                          />
                          <path
                            id="Path_77"
                            fill="#A0616A"
                            d="M504.055,198.114c0,0,1.7,16.981,4.528,19.812c2.828,2.83-19.812-1.7-19.812-1.7
                 s3.4-14.151,1.133-16.415C487.636,197.546,504.055,198.114,504.055,198.114z"
                          />
                          <path
                            id="Path_78"
                            fill="#A0616A"
                            d="M513.959,226.696c0,0,6.792,19.245,8.491,31.132c1.698,11.887,1.699,16.981,1.699,16.981
                 s7.358,22.641,5.095,33.399c0,0,8.49,25.473,1.132,25.473c-7.359,0-9.623-20.943-9.623-20.943l-11.322-53.775L513.959,226.696z"
                          />
                          <path
                            id="Path_79"
                            fill="#2F2E41"
                            d="M517.355,235.753c0,0-1.7-20.942-9.057-22.641c-3.513-0.811-9.219-2.008-14.224-3.039
                 c-8.726-1.862-17.615,2.366-21.675,10.312c-0.742,1.445-1.236,3.004-1.461,4.614c-1.132,9.057-6.792,9.057-1.7,20.943
                 c3.104,7.722,4.456,16.034,3.963,24.34l3.399,30c0,0-14.15,39.623-13.019,66.227l-13.021,65.095c0,0,2.83,6.792,10.755,3.4
                 c0,0,19.812-33.4,18.113-57.736l20.377-45.283c0,0,14.151,43.02,18.113,46.981l10.188,61.132c0,0,9.623,2.83,14.151,0
                 c0,0,1.7-58.868-7.924-68.49c0,0-9.623-40.755-7.358-51.509c2.266-10.754,2.83-27.17-14.15-41.888c0,0,2.264-6.226-5.095-9.623
                 l5.66-18.112L517.355,235.753z"
                          />
                          <path
                            id="Path_80"
                            fill="#A0616A"
                            d="M481.854,211.631c-0.152-0.022-0.309-0.04-0.468-0.055
                 c-4.909-0.356-9.177,3.335-9.533,8.244c-0.008,0.111-0.014,0.222-0.018,0.333l-0.896,20.129l-3.399,22.075l-7.358,47.547
                 c0,0-14.151,16.981-4.528,18.113s11.321-18.68,11.321-18.68l12.453-37.357l5.098-34.532c0,0,5.942-5.942,3.47-19.678
                 c-0.518-3.153-2.988-5.625-6.142-6.142V211.631z"
                          />
                          <path
                            id="Path_81"
                            fill="#2F2E41"
                            d="M449.432,429.338l-3.963,20.377c0,0-9.623,4.527-3.4,10.188
                 c6.224,5.661,14.718-1.132,14.718-1.132l2.264-6.792l-0.566,10.189h1.7l1.7-10.189c0,0,3.4-6.226,1.132-11.321v-6.793
                 L449.432,429.338z"
                          />
                          <path
                            id="Path_82"
                            fill="#2F2E41"
                            d="M526.699,431.319l-2.264,18.679c0,0-9.623,4.528-3.399,10.189
                 c6.223,5.661,14.717-1.132,14.717-1.132l2.264-6.797l-0.565,10.188h1.699l1.7-10.188c0,0,3.4-6.227,1.132-11.321v-6.793
                 L526.699,431.319z"
                          />
                          <path
                            id="Path_83"
                            fill="#2F2E41"
                            d="M504.078,168.881L504.078,168.881c-0.794,0.001-1.586,0.081-2.364,0.235
                 c-1.406-0.683-2.949-1.038-4.513-1.04h-0.41c-7.9,0-14.3,7.146-14.3,15.96h2.646l0.427-3.253l0.626,3.253h2.2
                 c-0.686,2.611-1.03,5.3-1.023,8v16.245h4l2.315-6.019l-0.579,6.019h25.714l2.106-5.472l-0.526,5.472h2.9v-12.759
                 C523.3,180.809,514.694,168.881,504.078,168.881z"
                          />
                        </g>
                        <g className="lamp_right">
                          <rect
                            id="Rectangle_65"
                            x="582.66"
                            y="272.568"
                            fill="#3F3D56"
                            width="1.061"
                            height="189.76"
                          />
                          <ellipse
                            id="Ellipse_16"
                            fill="#3F3D56"
                            cx="583.395"
                            cy="398.846"
                            rx="20.692"
                            ry="47.221"
                          />
                          <ellipse
                            id="Ellipse_17"
                            fill="#3F3D56"
                            cx="583.395"
                            cy="291.139"
                            rx="20.692"
                            ry="47.221"
                          />
                          <path
                            id="Path_84"
                            fill="#3F3D56"
                            d="M583.897,252.56l-1.017-0.3c0.063-0.216,6.3-21.593-3.941-35.355
                 c-5.786-7.773-15.713-11.714-29.508-11.714v-1.061c14.15,0,24.366,4.086,30.362,12.145
                 C590.357,230.464,583.967,252.341,583.897,252.56z"
                          />
                          <circle
                            id="Ellipse_18"
                            fill="#F2F2F2"
                            cx="549.436"
                            cy="204.655"
                            r="13.264"
                          />
                        </g>
                      </g>
                      <g>
                        <path
                          id="Path_69"
                          fill="#F2F2F2"
                          d="M445.47,223.42l-8.988,40.426l-7.3,32.8l-5.811,26.154l-7.292,32.8l-23.827,107.14
               L97,326.308l-21.7-10.03l-27.471-12.7l-21.71-10.029L0,281.484l14.8-21.538l12.311-17.909l15.573-22.658l12.306-17.9L193.481,0
               l146.706,130.075l26.125,23.162l20.831,18.47l26.128,23.162L445.47,223.42z"
                        />
                        <path
                          id="Path_70"
                          fill="#94BDB6"
                          d="M334.873,274.335l15.252,26.416l15.255,26.418h-61.009l15.251-26.416L334.873,274.335z"
                        />
                        <rect
                          id="Rectangle_61"
                          x="184.244"
                          y="222.451"
                          fill="#94BDB6"
                          width="63.962"
                          height="63.962"
                        />
                        <circle
                          id="Ellipse_14"
                          fill="#94BDB6"
                          cx="294.056"
                          cy="140.661"
                          r="41.038"
                        />

                        <path
                          id="Path_74"
                          fill="#3F3D56"
                          d="M304.243,186.791c-31.26,0-56.601-25.341-56.601-56.6s25.341-56.6,56.601-56.6
               c31.259,0,56.6,25.341,56.6,56.6S335.502,186.791,304.243,186.791L304.243,186.791z M304.243,74.716
               c-30.637,0-55.473,24.836-55.473,55.472s24.836,55.472,55.473,55.472c30.636,0,55.472-24.836,55.472-55.472l0,0
               C359.715,99.552,334.879,74.716,304.243,74.716z"
                        />

                        <rect
                          id="Rectangle_63"
                          x="245.717"
                          y="189.123"
                          transform="matrix(-0.4331 0.9014 -0.9014 -0.4331 547.303 35.149)"
                          fill="#3F3D56"
                          width="33.762"
                          height="1.132"
                        />

                        <rect
                          id="Rectangle_64"
                          x="297.375"
                          y="226.485"
                          transform="matrix(-0.4767 0.8791 -0.8791 -0.4767 671.2368 126.5814)"
                          fill="#3F3D56"
                          width="1.131"
                          height="73.207"
                        />
                        <path
                          id="Path_75"
                          fill="#3F3D56"
                          d="M266.32,286.977h-82.641v-82.638h82.641V286.977z M184.811,285.845h80.379v-80.374h-80.379
               V285.845z"
                        />
                        <path
                          id="Path_76"
                          fill="#3F3D56"
                          d="M388.868,327.736h-85.479l42.741-74.029L388.868,327.736z M305.347,326.604h81.56
               l-40.779-70.633L305.347,326.604z"
                        />

                        <text
                          className="text_t01"
                          transform="matrix(1 0 0 1 268.115 169.3999)"
                          fill="#505D68"
                          fontSize="10"
                          letterSpacing="1"
                        >
                          IDEATE
                        </text>

                        <text
                          className="text_t02"
                          transform="matrix(1 0 0 1 189.635 277.3999)"
                          fill="#505D68"
                          fontSize="10"
                          letterSpacing="1"
                        >
                          DESIGN
                        </text>

                        <text
                          className="text_t03"
                          transform="matrix(1 0 0 1 315.0256 323.3999)"
                          fill="#505D68"
                          fontSize="10"
                          letterSpacing="1"
                        >
                          SELL
                        </text>
                      </g>
                    </g>
                  </g>
                </svg>
                <img
                  className="Path_73"
                  // src={require("../../images/path01.svg")}
                  src={Innovation_image1}
                />
                <img
                  className="Path_02"
                  // src={require("../../images/path02.svg")}
                  src={Innovation_image2}
                />
                <img
                  className="Path_03"
                  // src={require("../../images/path03.svg")}
                  src={Innovation_image3}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="dont_miss">
          <div className="blog_miss">
            <div className="img_block01">
              <a href="#">
                <img src={require("../../images/miss-thing/img01.jpg")} />
              </a>
              <a href="#">
                <img src={require("../../images/miss-thing/img02.jpg")} />
              </a>
              <a href="#">
                <img src={require("../../images/miss-thing/img03.jpg")} />
              </a>
              <a href="#">
                <img src={require("../../images/miss-thing/img04.jpg")} />
              </a>
            </div>
            <div className="text_block">
              <div className="brans_content content-right">
                <h3 className="tittle_heading">
                  Don't <br />
                  miss a thing
                </h3>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                </p>
                <a href="#" className="view-btn">
                  Follow
                  <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    // xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    // xml:space="preserve"
                  >
                    <g>
                      <g>
                        <path
                          d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068
                       c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557
                       l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104
                       c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z"
                        ></path>
                      </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="design_modal">
        <div
          className="modal fade"
          id="password_modal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog mr-3">
            <div className="modal-content">
              <div className="modal-header p-0">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  <img src={require("../../images/close_btn.svg")} />
                </button>
              </div>
              <div className="modal-body">
                <div className="sign_inBox">
                  <h5 className="mb-5">Sign in</h5>
                </div>
                <form action="#" method="Post">
                  <input
                    className="form-control"
                    type="password"
                    name="text"
                    placeholder="Password"
                  />
                  <button
                    type="button"
                    className="btn btn-default text-uppercase"
                  >
                    Sign In
                  </button>
                </form>
                <h6>Having trouble signing in?</h6>
                <a className="help_btn" href="#">
                  Get Help
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
