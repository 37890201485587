import tt from "../../images/loin_img.svg";
const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer_up">
          <div className="container-flex">
            <div className="row">
              <div className="col-md-7 col-sm-12">
                <div className="footer_menu">
                  <ul className="ftr-main-menu">
                    <li>
                      <a
                        // onclick="myFunction()"
                        data-toggle="collapse"
                        href="#multiCollapseExample1"
                        role="button"
                        aria-expanded="false"
                        aria-controls="multiCollapseExample1"
                        href="#"
                      >
                        Company
                      </a>
                      <ul
                        className="collapse multi-collapse"
                        id="multiCollapseExample1"
                      >
                        <li>
                          <a href="#">About us</a>
                        </li>
                        <li>
                          <a href="#">Services</a>
                        </li>
                        <li>
                          <a href="#">Join us</a>
                        </li>
                        <li>
                          <a href="#">Contact</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#">story</a>
                    </li>
                    <li>
                      <a href="#">help</a>
                    </li>
                    <li>
                      <a href="#">policies</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-5 col-sm-12">
                <div className="news-letter">
                  <img
                    className="lion_img"
                    id="hide_cl"
                    // src={require("../../images/loin_img.svg")}
                    src={tt}
                    alt="img 1"
                  />
                  <h4>
                    Subscribe to our <br />
                    newsletter
                  </h4>
                  <h6>To stay ahead the crowd</h6>
                  <form action="#" method="Post">
                    <input
                      className="form-control"
                      type="email"
                      name="text"
                      placeholder="Email"
                      //   onFocus="this.placeholder = ''"
                      //   onBlur="this.placeholder = 'Email'"
                    />
                    <span className="animate_line"></span>
                    <button type="button" className="btn btn-default">
                      Sign Up
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer_bottom">
          <p>
            &copy; 2020 Black Bubble Pvt. Ltd. <br />
            All Right Reserved.
          </p>
          <div className="border_color"></div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
