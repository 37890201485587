import React from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import App from "./App";
import { AppProvider } from "./components/Provider";

ReactDOM.render(
  <React.StrictMode>
    {/* <AppProvider> */}
    <App />
    {/* </AppProvider> */}
  </React.StrictMode>,
  document.getElementById("root")
);

// "proxy":"http://127:0.0.1:5000",
