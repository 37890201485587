import PIC from "../../images/pic.jpg";
import ArrowRight from "../../images/arrow_right.svg";
import ADD from "../../images/add.svg";
import Star from "../../images/star.svg";
import Slider from "react-slick";

const ProductPage = () => {
  const productSlide = {
    centerMode: true,
    dots: true,
    centerPadding: "0px !important",
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 300,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const videoSlide = {
    centerMode: true,
    dots: false,
    centerPadding: "0px -1rem 0px !important",
    infinite: true,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 300,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const designNewSlide = {
    centerMode: true,
    dots: false,
    centerPadding: "0px",
    infinite: true,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    speed: 300,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="container-flex">
      <section className="product_page">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <Slider {...productSlide} className="product_slide">
              <div>
                <img src={PIC} alt="img" height={600} />
              </div>
              <div>
                <img src={PIC} alt="img" height={600} />
              </div>
              <div>
                <img src={PIC} alt="img" height={600} />
              </div>
              <div>
                <img src={PIC} alt="img" height={600} />
              </div>
              <div>
                <img src={PIC} alt="img" height={600} />
              </div>
              <div>
                <img src={PIC} alt="img" height={600} />
              </div>
            </Slider>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="product_detail">
              <h5>RIVAAYAT</h5>
              <h4>Neolith Bowl</h4>
              <h6>₹ 990</h6>
              <div className="product_type">
                <ul className="select_size">
                  <li className="select_li">
                    <select>
                      <option data-display="Style">Style</option>
                      <option value="1">Option 1</option>
                      <option value="2">Option 2</option>
                    </select>
                  </li>
                  <li className="select_li">
                    <select>
                      <option data-display="Size">Size</option>
                      <option value="1">Option 1</option>
                      <option value="2">Option 2</option>
                    </select>
                  </li>
                </ul>
              </div>
              <div className="product_count">
                <input type="number" value="0" min="0" max="1000" step="1" />
                <span className="earn_point d-none">Earn 30 points</span>
              </div>
              <div className="buy_div">
                <button className="buy_btn">Buy Now</button>
                <form className="d-none">
                  <input type="text" placeholder="Enter Pincode" />
                  <button>
                    <img src={ArrowRight} />
                  </button>
                </form>
              </div>

              <a href="bag-card.html" className="add_toCart">
                ADD TO BAG
              </a>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et.
              </p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing.</p>
              <a className="more_detail" href="#">
                More Details <img src={ADD} />
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="inviting_designer">
        <div className="row">
          <div className="col-sm-12">
            <h2 className="tittle_heading text-center">
              Why Neolith is Awesome?
            </h2>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4 align-self-center">
            <div className="content-left">
              <p>Here's what Mrs. Lorem, an amazing chef, thinks of Neolith</p>
              <a href="#" className="view-btn">
                View More
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  // xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                  // xml:space="preserve"
                >
                  <g>
                    <g>
                      <path
                        d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068
                      c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557
                      l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104
                      c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z"
                      ></path>
                    </g>
                  </g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg>
              </a>
            </div>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-8">
            <Slider {...videoSlide} className="video_slide">
              <div className="product_video">
                <video controls>
                  <source
                    src={require("../../images/video.mp4")}
                    type="video/mp4"
                  />
                  {/* <source
                    src={require("../../images/video.webm")}
                    type="video/webm"
                  />
                  <source
                    src={require("../../images/video.ogg")}
                    type="video/ogg"
                  /> */}
                  Video tag is not supported in this browser.
                </video>
              </div>
              <div className="product_video">
                <video controls>
                  <source
                    src={require("../../images/video.mp4")}
                    type="video/mp4"
                  />
                  {/* <source
                    src={require("../../images/video.webm")}
                    type="video/webm"
                  />
                  <source
                    src={require("../../images/video.ogg")}
                    type="video/ogg"
                  /> */}
                  Video tag is not supported in this browser.
                </video>
              </div>
              <div className="product_video">
                <video controls>
                  <source
                    src={require("../../images/video.mp4")}
                    type="video/mp4"
                  />
                  {/* <source
                    src={require("../../images/video.webm")}
                    type="video/webm"
                  />
                  <source
                    src={require("../../images/video.ogg")}
                    type="video/ogg"
                  /> */}
                  Video tag is not supported in this browser.
                </video>
              </div>
              <div className="product_video">
                <video controls>
                  <source
                    src={require("../../images/video.mp4")}
                    type="video/mp4"
                  />
                  {/* <source
                    src={require("../../images/video.webm")}
                    type="video/webm"
                  />
                  <source
                    src={require("../../images/video.ogg")}
                    type="video/ogg"
                  /> */}
                  Video tag is not supported in this browser.
                </video>
              </div>
            </Slider>
          </div>
        </div>
      </section>

      <section className="custmer_review">
        <h2 className="tittle_heading">Customer Reviews</h2>
        <div className="review_icon">
          <div>
            <img src={Star} />
            <img src={Star} />
            <img src={Star} />
            <img src={Star} />
            <img src={Star} />
            <span className="total_review">54</span>
          </div>
          <button>
            Write Review{" "}
            <span className="">
              <img src={require("../../images/write.svg")} />
            </span>
          </button>
        </div>
        <div className="user_div">
          <div className="user_profile">
            <div className="user_picture">
              <div className="user_img">
                <img src={require("../../images/user.png")} alt="img" />
              </div>
            </div>
            <div className="user_detail">
              <h4>Tarun S.B.</h4>
              <div className="star">
                <img src={Star} />
                <img src={Star} />
                <img src={Star} />
                <img src={Star} />
                <img src={Star} />
              </div>
              <h5>vitae tortor condimentum.</h5>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Turpis tincidunt id aliquet risus feugiat in ante metus dictum.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>
          <div className="iten_holder">
            <div className="holder_pic">
              <img src={require("../../images/pic.jpg")} />
            </div>
            <div className="holder_pic">
              <img src={require("../../images/pic.jpg")} />
            </div>
          </div>
        </div>
        <div className="user_div">
          <div className="user_profile">
            <div className="user_picture">
              <div className="user_img">
                <img src={require("../../images/user.png")} alt="img" />
              </div>
            </div>
            <div className="user_detail">
              <h4>Neha Arya</h4>
              <div className="star">
                <img src={Star} />
                <img src={Star} />
                <img src={Star} />
                <img src={Star} />
                <img src={Star} />
              </div>
              <h5>blandit massa</h5>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Turpis tincidunt id aliquet risus feugiat in ante metus dictum.
              </p>
            </div>
          </div>
          <div className="iten_holder">
            <div className="holder_pic">
              <img src={require("../../images/pic.jpg")} />
            </div>
            <div className="holder_pic">
              <img src={require("../../images/pic.jpg")} />
            </div>
          </div>
        </div>
        <div className="user_div">
          <div className="user_profile">
            <div className="user_picture">
              <div className="user_img">
                <img src={require("../../images/user.png")} alt="img" />
              </div>
            </div>
            <div className="user_detail">
              <h4>B. Soumya</h4>
              <div className="star">
                <img src={Star} />
                <img src={Star} />
                <img src={Star} />
                <img src={Star} />
                <img src={Star} />
              </div>
              <h5>vitae tortor condimentum.</h5>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Turpis tincidunt id aliquet risus feugiat in ante metus dictum.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>
          <div className="iten_holder">
            <div className="holder_pic">
              <img src={require("../../images/pic.jpg")} />
            </div>
            <div className="holder_pic">
              <img src={require("../../images/pic.jpg")} />
            </div>
          </div>
        </div>
        <a href="#" className="view-btn d-block text-right mt-2">
          View All
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            // xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            // xml:space="preserve"
          >
            <g>
              <g>
                <path
                  d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068
                c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557
                l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104
                c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z"
                ></path>
              </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
          </svg>
        </a>
      </section>

      <section className="perfect_item">
        <h2 className="tittle_heading">
          Neolith goes perfectly <br />
          with these items
        </h2>
        <div className="row">
          <div className="col-sm-12 col-md-4">
            <div className="item_per">
              <a href="#">
                <img src={require("../../images/Our-Brands/img01.jpg")} />
                <h6>massa</h6>
              </a>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <div className="item_per">
              <a href="#">
                <img src={require("../../images/Our-Brands/img02.jpg")} />
                <h6 className="text-dark">massa</h6>
              </a>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <div className="item_per">
              <a href="#">
                <img src={require("../../images/Our-Brands/img03.jpg")} />
                <h6>massa</h6>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="trand-now for_you">
        <h2 className="tittle_heading text-left">
          Designs you
          <br /> may also like
        </h2>
        <Slider {...designNewSlide} className="designs_new">
          <div className="design_items">
            <img src={require("../../images/trending-now/img01.jpg")} />
            <h6 className="mini-tittle">Lacus</h6>
            <button className="price-btn">₹ 499</button>
          </div>
          <div className="design_items">
            <img
              src={require("../../images/trending-now/img02.jpg")}
              alt="img"
            />
            <h6 className="mini-tittle">Odio</h6>
            <button className="price-btn">₹ 2199</button>
          </div>
          <div className="design_items">
            <img
              src={require("../../images/trending-now/img03.jpg")}
              alt="img"
            />
            <h6 className="mini-tittle">Orcarc</h6>
            <button className="price-btn">₹ 3199</button>
          </div>
          <div className="design_items">
            <img
              src={require("../../images/trending-now/img04.jpg")}
              alt="img"
            />
            <h6 className="mini-tittle">Egestas</h6>
            <button className="price-btn">₹ 899</button>
          </div>
          <div className="design_items">
            <img
              src={require("../../images/trending-now/img05.jpg")}
              alt="img"
            />
            <h6 className="mini-tittle">Justuo</h6>
            <button className="price-btn">₹ 999</button>
          </div>
          <div className="design_items">
            <img
              src={require("../../images/trending-now/img06.jpg")}
              alt="img"
            />
            <h6 className="mini-tittle">Magnai</h6>
            <button className="price-btn">₹ 4499</button>
          </div>
          <div className="design_items">
            <img
              src={require("../../images/trending-now/img07.jpg")}
              alt="img"
            />
            <h6 className="mini-tittle">Factri</h6>
            <button className="price-btn">₹ 2599</button>
          </div>
          <div className="design_items">
            <img
              src={require("../../images/trending-now/img08.jpg")}
              alt="img"
            />
            <h6 className="mini-tittle">Purus</h6>
            <button className="price-btn">₹ 1299</button>
          </div>
          <div className="design_items">
            <img
              src={require("../../images/trending-now/img09.jpg")}
              alt="img"
            />
            <h6 className="mini-tittle">Donec</h6>
            <button className="price-btn">₹ 1299</button>
          </div>
          <div className="design_items">
            <img
              src={require("../../images/trending-now/img10.jpg")}
              alt="img"
            />
            <h6 className="mini-tittle">Arcu</h6>
            <button className="price-btn">₹ 699</button>
          </div>
        </Slider>
      </section>
    </div>
  );
};

export default ProductPage;
