import ArrowLeft from "../../images/arrow_left.svg";
import ArrowRight from "../../images/arrow_right.svg";
import LionIcon from "../../images/loin_img.svg";

const Login = () => {
  return (
    <div className="container-flex">
      <div className="center_div">
        <a href="bag-card.html">
          <img src={ArrowLeft} />
        </a>
        <div aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">Bag</a>
            </li>
            <li className="breadcrumb-item">
              <a className="active" href="#">
                Info
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="#">Review</a>
            </li>
            <li className="breadcrumb-item">
              <a href="#">Payment</a>
            </li>
            <li className="breadcrumb-item">
              <a href="#">Order Placed</a>
            </li>
          </ol>
        </div>
        <form>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <div className="content_div">
                <h4>Guest Checkout</h4>
                <div className="form-group">
                  <input
                    id="form_name1"
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label htmlFor="form_name1">Email/Mobile no.</label>
                </div>
                <a href="address1.html" className="proceed_order">
                  Proceed to order <img src={ArrowRight} />
                </a>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="content_div">
                <h4>Log in for offers</h4>
                <div className="form-group">
                  <input
                    id="form_name2"
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label for="form_name2">Email/Mobile no.</label>
                </div>
                <div className="form-group">
                  <input
                    id="form_name3"
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label for="form_name3">Password</label>
                </div>
                <a href="address1.html" className="proceed_order">
                  SIGN IN & ORDER <img src={LionIcon} />
                </a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
