import PrevButton from "../../images/prev.svg";
import NextButton from "../../images/next.svg";

const Categories = () => {
  return (
    <>
      <div class="container-flex">
        <section class="category_page">
          <div class="row">
            <div class="col-sm-12">
              <h2 class="tittle_heading text-center">Lighting</h2>
              <div class="filter">
                <a class="list_filter" href="#">
                  Filter
                </a>
                <div class="select_sort">
                  <select>
                    <option data-display="Sort">Sort</option>
                    <option value="1">Option 1</option>
                    <option value="2">Option 2</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row perfect_item">
            <div class="col-12 col-sm-6 col-md-3">
              <div class="item_per">
                <a href="#">
                  <img src={require("../../images/trending-now/img01.jpg")} />
                  <h6>massa</h6>
                </a>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
              <div class="item_per">
                <a href="#">
                  <img src={require("../../images/trending-now/img02.jpg")} />
                  <h6>massa</h6>
                </a>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
              <div class="item_per">
                <a href="#">
                  <img src={require("../../images/trending-now/img03.jpg")} />
                  <h6>massa</h6>
                </a>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
              <div class="item_per">
                <a href="#">
                  <img src={require("../../images/trending-now/img04.jpg")} />
                  <h6>massa</h6>
                </a>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
              <div class="item_per">
                <a href="#">
                  <img src={require("../../images/trending-now/img01.jpg")} />
                  <h6>massa</h6>
                </a>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
              <div class="item_per">
                <a href="#">
                  <img src={require("../../images/trending-now/img02.jpg")} />
                  <h6>massa</h6>
                </a>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
              <div class="item_per">
                <a href="#">
                  <img src={require("../../images/trending-now/img03.jpg")} />
                  <h6>massa</h6>
                </a>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
              <div class="item_per">
                <a href="#">
                  <img src={require("../../images/trending-now/img04.jpg")} />
                  <h6>massa</h6>
                </a>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
              <div class="item_per">
                <a href="#">
                  <img src={require("../../images/trending-now/img01.jpg")} />
                  <h6>massa</h6>
                </a>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
              <div class="item_per">
                <a href="#">
                  <img src={require("../../images/trending-now/img02.jpg")} />
                  <h6>massa</h6>
                </a>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
              <div class="item_per">
                <a href="#">
                  <img src={require("../../images/trending-now/img03.jpg")} />
                  <h6>massa</h6>
                </a>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
              <div class="item_per">
                <a href="#">
                  <img src={require("../../images/trending-now/img04.jpg")} />
                  <h6>massa</h6>
                </a>
              </div>
            </div>
          </div>

          <ul class="pagination justify-content-center">
            <li class="page-item disabled">
              <a class="page-link mr-5" href="#" tabindex="-1">
                <img src={PrevButton} />
              </a>
            </li>
            <li class="page-item">
              <a class="page-link active" href="#">
                1
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                2
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                3
              </a>
            </li>
            <li class="page-item">
              <a class="page-link ml-5" href="#">
                <img src={NextButton} />
              </a>
            </li>
          </ul>
        </section>

        <section class="our-brands category_brands">
          <div class="row">
            <div class="col-sm-12 col-md-8">
              <div class="brans_img">
                <div class="brand-img animation-effect">
                  <a href="product-page.html">
                    <img
                      src={require("../../images/Our-Brands/img01.jpg")}
                      alt="brand-01"
                    />
                    <h6 class="tittle-brand">massa</h6>
                  </a>
                </div>
                <div class="brand-img animation-effect">
                  <a href="product-page.html">
                    <img
                      src={require("../../images/Our-Brands/img02.jpg")}
                      alt="brand-01"
                    />
                    <h6 class="tittle-brand text-dark">VELIT</h6>
                  </a>
                </div>
                <div class="brand-img animation-effect">
                  <a href="product-page.html">
                    <img
                      src={require("../../images/Our-Brands/img03.jpg")}
                      alt="brand-01"
                    />
                    <h6 class="tittle-brand">TELLUS</h6>
                  </a>
                </div>
              </div>
              <div class="brans_img">
                <div class="brand-img animation-effect">
                  <a href="product-page.html">
                    <img
                      src={require("../../images/Our-Brands/img01.jpg")}
                      alt="brand-01"
                    />
                    <h6 class="tittle-brand">massa</h6>
                  </a>
                </div>
                <div class="brand-img animation-effect">
                  <a href="product-page.html">
                    <img
                      src={require("../../images/Our-Brands/img02.jpg")}
                      alt="brand-01"
                    />
                    <h6 class="tittle-brand text-dark">VELIT</h6>
                  </a>
                </div>
                <div class="brand-img animation-effect">
                  <a href="product-page.html">
                    <img
                      src={require("../../images/Our-Brands/img03.jpg")}
                      alt="brand-01"
                    />
                    <h6 class="tittle-brand">TELLUS</h6>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-4 d-flex align-items-center">
              <div class="brans_content content-right w-100">
                <h2 class="tittle_heading mb-4">
                  Discover <br />
                  Dinnerware
                </h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Lorem ipsum dolor sit amet, consectetur adipiscing
                </p>
                <a href="#" class="view-btn">
                  View All
                  <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    // xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    // xml:space="preserve"
                  >
                    <g>
                      <g>
                        <path
                          d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068
                      c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557
                      l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104
                      c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z"
                        ></path>
                      </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Categories;
