import closeButton from "../../images/close_btn.svg";
import googleHangouts from "../../images/google-hangouts.svg";

export const SignInSignUpModal = ({ setShowLoginModal }) => {
  return (
    <div className="design_modal">
      <div
        className="modal fade show"
        id="signIn_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
        style={{ display: "block", opacity: 1 }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header p-0">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={() => setShowLoginModal(false)}
              >
                <img src={closeButton} />
              </button>
            </div>
            <div className="modal-body">
              <div className="sign_inBox">
                <h5 className="mb-0">Sign in</h5>
                <p className="my-2">OR</p>
                <h5 className="mt-0">Sign up</h5>
              </div>
              <form action="#" method="Post">
                <input
                  className="form-control"
                  type="email"
                  name="text"
                  placeholder="Email/Mobile No."
                />
                <button
                  type="button"
                  className="btn btn-default text-uppercase"
                >
                  Proceed
                </button>
              </form>
              <a
                className="google_sign"
                data-toggle="modal"
                data-target="#password_modal"
                // href="#"
                style={{ textDecoration: "none" }}
              >
                <img src={googleHangouts} /> Sign in with Google
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ShowFirstTime = ({ setShowFirstTimeModal }) => {
  return (
    <div className="design_modal">
      <div
        className="modal fade show"
        id="home_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
        style={{ display: "block", opacity: 1 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header p-0">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={() => setShowFirstTimeModal(false)}
              >
                <img src={closeButton} />
              </button>
            </div>
            <div className="modal-body">
              <h4>First Order?</h4>
              <h5>Get FLAT 10% OFF </h5>
              <p>Secret: 15% OFF on 3rd order if you sign up</p>
              <form action="#" method="Post">
                <input
                  className="form-control"
                  type="email"
                  name="text"
                  placeholder="Email"
                />
                <button type="button" className="btn btn-default">
                  Sign Up
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
