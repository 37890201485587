import HomePage from "./components/HomePage";
import "./css/App.css";
import "./css/bootstrap.min.css";
import "./css/flaticon.css";
import "./css/slick.css";
import "./css/slick-theme.css";
import "./css/style.css";
import "./css/responsive.css";
import "./css/easy-responsive-tabs.css";
import "./css/nice-select.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Login from "./components/Login";
import DashBoard from "./components/Dashboard";
import Categories from "./components/Categories";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
import { ShowFirstTime } from "./components/modals";
import ProductPage from "./components/ProductPage";
import BagCart from "./components/BagCard";

function App() {
  const [ShowFirstTimeModal, setShowFirstTimeModal] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFirstTimeModal(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<DashBoard />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/product-page" element={<ProductPage />} />
          <Route path="/bag-cart" element={<BagCart />} />
        </Routes>
        <Footer />
        {ShowFirstTimeModal && (
          <ShowFirstTime setShowFirstTimeModal={setShowFirstTimeModal} />
        )}
      </div>
    </Router>
  );
}

export default App;
