const DashBoard = () => {
  return (
    <>
      <section class="dashboard-profile">
        <div class="dashboard-profile-section">
          <div class="container-fluid">
            <div class="user-profile">
              <img src={require("../../images/user.png")} />
              <div class="user-detail">
                <span class="user-wl">Welcome back !</span>
                <span class="user-name">Tarun B</span>
              </div>
            </div>
            <div id="nested-tabInfo">
              <div id="parentVerticalTab">
                <ul class="resp-tabs-list hor_1">
                  <li>Profile</li>
                  <li>Account</li>
                  <li>Address</li>
                  <li>Membership</li>
                  <li>Wishlist</li>
                  <li>Orders</li>
                </ul>
                <div class="resp-tabs-container hor_1">
                  <div class="Profile-info">
                    <div class="fill-detail">
                      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                        <label>Name</label>
                      </div>
                      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                        <input
                          type="text"
                          class="form-control"
                          name=""
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div class="fill-detail">
                      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                        <label>Email:</label>
                      </div>
                      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                        <input
                          type="email"
                          class="form-control"
                          name=""
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div class="fill-detail">
                      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                        <label>Mobile No. :</label>
                      </div>
                      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                        <input
                          type="text"
                          class="form-control"
                          name=""
                          placeholder="Mobile Number"
                        />
                      </div>
                    </div>
                    <div class="fill-detail">
                      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                        <label>Alternate No. :</label>
                      </div>
                      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                        <input
                          type="text"
                          class="form-control"
                          name=""
                          placeholder="Phone Number"
                        />
                      </div>
                    </div>
                    <div class="fill-detail">
                      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                        <label>DOB :</label>
                      </div>
                      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                        <input
                          type="date"
                          class="form-control"
                          name=""
                          placeholder="Date"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="Account-info">
                    <div class="fill-detail">
                      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                        <label>Email</label>
                      </div>
                      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                        <input
                          type="email"
                          class="form-control"
                          name=""
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div class="fill-detail">
                      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                        <label>Mobile No. :</label>
                      </div>
                      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                        <input
                          type="text"
                          class="form-control"
                          name=""
                          placeholder="Phone number"
                        />
                      </div>
                    </div>
                    <div class="fill-detail">
                      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                        <label>OTP :</label>
                      </div>
                      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                        <input
                          type="Password"
                          class="form-control"
                          name=""
                          placeholder="Enter OTP"
                        />
                      </div>
                    </div>
                    <div class="fill-detail">
                      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                        <label>Password</label>
                      </div>
                      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                        <input
                          type="Password"
                          class="form-control"
                          name=""
                          placeholder="Password"
                        />
                      </div>
                    </div>
                    <div class="fill-detail">
                      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                        <label>DOB:</label>
                      </div>
                      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                        <input
                          type="date"
                          class="form-control"
                          name=""
                          placeholder="Date"
                        />
                      </div>
                    </div>
                    <div class="fill-detail">
                      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                        <label>Confirm Password:</label>
                      </div>
                      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                        <input
                          type="text"
                          class="form-control"
                          name=""
                          placeholder="min 8 characters"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="Address-info">
                    <div class="fill-detail pb-1">
                      <div class="col-xl-12 col-lg-12 col-md-6 col-sm-12">
                        <label>Default Address</label>
                        <label class="my-3 d-flex justify-content-between">
                          Soumya B{" "}
                          <a href="#" class="dash-view-btn edit-btn">
                            Home
                          </a>
                        </label>
                        <p>741/2</p>
                        <p>Mufaddal Enclave, 17 Cross, JP Nagar</p>
                        <p>Bangalore</p>
                        <p>560078</p>
                        <p>Karnataka</p>
                        <p>INDIA</p>
                        <p>Mobile : +91 9876543210</p>
                      </div>
                    </div>
                    <div class="fill-detail pb-1">
                      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <a href="address.html" class="dash-view-btn edit-btn">
                          {" "}
                          Edit
                        </a>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-3">
                        <div class="content-left">
                          <a href="address-view-all.html" class="dash-view-btn">
                            View All
                            <svg
                              version="1.1"
                              id="Capa_1"
                              xmlns="http://www.w3.org/2000/svg"
                              //   xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="0 0 512 512"
                              //   xml:space="preserve"
                            >
                              <g>
                                <g>
                                  <path
                                    d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068
                    c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557
                    l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104
                    c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z"
                                  ></path>
                                </g>
                              </g>
                              <g></g>
                              <g></g>
                              <g></g>
                              <g></g>
                              <g></g>
                              <g></g>
                              <g></g>
                              <g></g>
                              <g></g>
                              <g></g>
                              <g></g>
                              <g></g>
                              <g></g>
                              <g></g>
                              <g></g>
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="Membership-info Orde-info">
                    <p> Comming Soon</p>
                  </div>
                  <div class="Wishlist-info Orde-info">
                    <p> No Available</p>
                  </div>
                  <div class="Orders-info">
                    <div class="order-info row">
                      <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                        <div class="order-img">
                          <img src="images/Our-Brands/img01.jpg" alt="" />
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="order-deatil">
                          <h3>Neolith Bowl</h3>
                          <h5>Neolith Bowl</h5>
                          <div class="qty-info">
                            <span>Qty: 2</span>
                          </div>
                          <div class="qty-info-2">
                            <span>&#8377;990</span>
                          </div>
                          <div class="qty-info">
                            <span>Order Id : xxxxxxxxxx</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                        <div class="qty-info-2">
                          <h5>Delivered</h5>
                        </div>
                        <div class="qty-info">
                          <span>dd/mm/yy</span>
                        </div>
                      </div>
                    </div>
                    <div class="order-info row">
                      <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                        <div class="order-img">
                          <img src="images/Our-Brands/img02.jpg" alt="" />
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="order-deatil">
                          <h3>Medeline Light</h3>
                          <h5>Neolith Bowl</h5>
                          <div class="qty-info">
                            <span>Qty: 2</span>
                          </div>
                          <div class="qty-info-2">
                            <span>&#8377;990</span>
                          </div>
                          <div class="qty-info">
                            <span>Order Id : xxxxxxxxxx</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                        <div class="qty-info-2">
                          <h5>Confirmed</h5>
                        </div>
                        <div class="qty-info">
                          <span>Track</span>
                        </div>
                      </div>
                    </div>
                    <div class="order-info row">
                      <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                        <div class="order-img">
                          <img src="images/Our-Brands/img03.jpg" alt="" />
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="order-deatil">
                          <h3>Neolith Bowl</h3>
                          <h5>Neolith Bowl</h5>
                          <div class="qty-info">
                            <span>Qty: 2</span>
                          </div>
                          <div class="qty-info-2">
                            <span>&#8377;990</span>
                          </div>
                          <div class="qty-info">
                            <span>Order Id : xxxxxxxxxx</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                        <div class="qty-info-2">
                          <h5>On the way</h5>
                        </div>
                        <div class="qty-info">
                          <span>Track</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="apply-btn">
              <div class="content-left text-center">
                <a href="#" class="dash-view-btn">
                  View Order
                  <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    // xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    // xml:space="preserve"
                  >
                    <g>
                      <g>
                        <path
                          d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068
                    c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557
                    l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104
                    c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z"
                        ></path>
                      </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DashBoard;
